<template>
    <router-view></router-view>
    <h1>Purchase Order</h1>
    <hr />
    <bs-error-message :errors="errorMessages" />

    <div class="row justify-content-between">
        <div class="col-md-6">
            <div  class="d-grid gap-3 d-md-block">
                <button v-show="isAuthCreate" @click="createPO()" class="btn btn-primary btn-sm me-md-1">
                    <i class="bi bi-file-earmark"></i> Create PO
                </button>
            </div>
        </div>
        <div class="col-md-6">
            <div class="pagination-right">
                <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-stripped">
            <thead>
                <tr>
                    <th>Vendor Code</th>
                    <th>Vendor Name</th>
                    <th>PO Number</th>
                    <th>PO Created By</th>
                    <th>PO Created On</th>
                    <th>MIRO Doc</th>
                    <th>MIRO Year</th>
                    <th>Payment Due Date</th>
                    <th>Payment Clearing</th>
                </tr>
                <tr>
                    <th>
                        <bs-textbox placeholder="Vendor Code" v-model="filter.VendorCodeContains" @keyup="search()" />
                    </th>
                    <th>
                        <bs-textbox placeholder="Vendor Name" v-model="filter.VendorNameContains" @keyup="search()" />
                    </th>
                    <th>
                        <bs-textbox placeholder="PO Number" v-model="filter.PONumberContains" @keyup="search()" />
                    </th>
                    <th>
                        <bs-textbox placeholder="PO Created By" v-model="filter.POCreatedByContains" @keyup="search()" />
                    </th>
                    <th>
                        <bs-date-picker placeholder="PO Created On From" v-model="filter.POCreatedDateOnFrom" @change="search()" />
                        <bs-date-picker placeholder="PO Created On Until" v-model="filter.POCreatedDateOnUntil" @change="search()" />
                    </th>
                    <th>
                        <bs-textbox placeholder="MIRO Doc" v-model="filter.MIRODocContains" @keyup="search()" />
                    </th>
                    <th>
                        <bs-currency placeholder="MIRO Year From" v-model="filter.MIROYearFrom" @blur="search()" />
                        <bs-currency placeholder="MIRO Year Until" v-model="filter.MIROYearUntil" @blur="search()" />
                    </th>
                    <th>
                        <bs-date-picker placeholder="Payment Due Date From" v-model="filter.PaymentDueDateFrom" @change="search()" />
                        <bs-date-picker placeholder="Payment Due Date Until" v-model="filter.PaymentDueDateUntil" @change="search()" />
                    </th>
                    <th>
                        <bs-textbox placeholder="Payment Clearing" v-model="filter.PaymentClearingContains" @keyup="search()" />
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="data != null && data.length > 0">
                    <tr v-for="item in data" :key="item.id">
                        <td>{{item.vendorCode}}</td>
                        <td>{{item.vendorName}}</td>
                        <td>
                            <button @click="detailItem(item)" class="btn btn-link">
                                {{item.poNumber}}
                            </button>
                        </td>
                        <td>{{item.poCreatedBy}}</td>
                        <td><view-date v-model="item.poCreatedOn" /></td>
                        <td>{{item.miroDoc}}</td>
                        <td>{{item.miroYear}}</td>
                        
                        <td><view-date v-model="item.paymentDueDate" /></td>
                        <td>{{item.paymentClearing}}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import PurchaseOrderService from "@/services/purchase-order-service.js";
import SecurityService from "@/services/security-service.js";
export default {
    data: function () {
        return {
            filter: {
                codeContains: '',
                page: 1,
                perPage: 10,
                orderByProperty: 'invoiceDate',
                orderType: 0
            },
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: Array(),
            isAuthCreate: false,
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == "/purchase-order") {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
        this.checkRole();
    },
    methods: {
        async createPO() {
            this.$router.push(`/purchase-order/create`);
        },
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async reorder(property, orderType) {
            this.filter.orderByProperty = property;
            this.filter.orderType = orderType;
            this.loadData();
        },
        async search() {
            this.filter.page = 1;
            this.loadData();
        },
        async loadData() {
            const result = await PurchaseOrderService.getList(this.filter);
            if (result.status) {
                this.errorMessages = null; 
            }
            else {
                this.errorMessages = result.errorMessages;
            }

            const data = result.data;
            this.pageNumber = data.pageNumber;
            this.perPage = data.perPage;
            this.totalPage = data.totalPage;
            this.totalRow = data.totalRow;
            this.data = data.data;
        },
        async detailItem(item) {
            this.$router.push(`/purchase-order/detail/${item.id}`);
        },
        async checkRole() {
            const securityService = new SecurityService();

            if (await securityService.allowAccessAdmin("PURCHASEORDER", "CREATE")) {
                this.isAuthCreate = true;
            } else {
                this.isAuthCreate = false;
            }

        }
    }
}
</script>

<style scoped>
    th, td {
        white-space: nowrap;
    }
</style>